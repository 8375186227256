<template>
	<selecterTemp :labels_prop="labels" :values="values" @change="key=$event.target.value"></selecterTemp>
    <div class="app">
        <div v-show="skeleton">
            <skeleton-Temp bg="#ddd" :count="10"></skeleton-Temp>
        </div>
        <div v-show="!(skeleton)">
            <player-info v-for="(player,index) in player_data_sorted" :data="player" :index="index" :bmc="bmc" :sort="key" v-bind:key="player.playerName" @bmc='bmc = $event'></player-info>
        </div>
    </div>
    <a style="margin-bottom: 20px;text-decoration: underline;" href="/ranking">->一覧で表示</a>
</template>

<script>
import selecterTemp from './components/selecter.vue'// eslint-disable-line no-unused-vars
import playerInfo from './components/playerTemp.vue'// eslint-disable-line no-unused-vars
import skeletonTemp from './components/skeletonTemp.vue'// eslint-disable-line no-unused-vars

export default {
	name: 'App',
	components: {// eslint-disable-line no-unused-vars
		playerInfo,// eslint-disable-line no-unused-vars
        // eslint-disable-next-line
        selecterTemp,// eslint-disable-line no-unused-vars
        skeletonTemp// eslint-disable-line no-unused-vars
	},
	data (){
        return{
            player_data:[],// eslint-disable-line
            key:"current",
			bmc:false,
            values:["current", "best", "seasonBest"],
            labels:["current", "best", "season"],
            skeleton:true
        }
    },
	methods:{
        order () {
            const keyNew = String(this.key);// eslint-disable-line no-unused-vars
            this.player_data = this.player_data.sort(function(a,b){// eslint-disable-line no-unused-vars
                if(a[keyNew] > b[keyNew]) return -1;
                if(a[keyNew] < b[keyNew]) return 1;
            })
        },
    },
	computed:{
		player_data_sorted:function(){
			var res = []
			this.order();
			if(this.bmc){
				res = this.player_data.filter(function(player){
					return player.bmc==true
					})
			}
			else{
				res = this.player_data;
			}
			return res
		}
	},
	created : function() {
        fetch('https://clagon.hasura.app/api/rest/data/all')
        .then(response => response.json())
        .then(data => {
            this.player_data = data.data
            this.skeleton = false
        })
        .catch((error) => {
            console.error('Error:', error);
            this.skeleton = false
        });
    },
}

</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

*
{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
    /* transition: all .5s; */
    font-family: 'Work Sans', sans-serif !important;
    -webkit-tap-highlight-color: transparent;
}

html,body
{
    width: 100%;
    height: 100%;
    position: static;
    background-color:#eeeeee;
    color: #444444;
    /* background-color:#414141;
    color: #f1f1f1; */
    /* background-color: #709fb0; */
}


.container
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.app
{
    display: flex;
    justify-content: center;
    align-items: center;
}

a
{
    text-decoration: none;
    /* color:#ffffff; */
    color:#000000
}

@media(max-width:1024px)
{
    .player
    {
        height:185px;
    }
}
</style>
