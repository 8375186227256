
<template>
    <div v-for="num in count" :key="num" class="player" :style="'background-color:'+bg+';'+'--primary:'+primary">
        <div class="player__status">
            <p class="player__status--index primary">00</p>
            <div class="player__status--league-img primary"></div>
            <div>
                <div class="player__status-info">
                    <p class="player__status-info--name primary">onamae</p>
                    <br>
                    <p class="player__status-info--clan primary">onamae</p>
                </div>
            </div>
        </div>
        <span class="player__rank"></span>
        <div class="player__trophy-youtube">
            <div class="ytico"></div>
            <div class="player__trophy" style="margin-top:10px;margin-left: 20px;">
                <span class="player__trophy-icon"><span class="player__trophy-icon-inner primary" style="margin-right:5px">IO0</span><span class="player__trophy-icon--trophy primary">888888</span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'skeletonTemp',
    props:{
        bg:{
            "type":String,
            "default":"#eeeeee"
        },
        primary:{
            "type":String,
            "default":"#ccc"
        },
        count:{
            "type":Number,
            "default":10
    }
    },

	methods:{
		move(e) {
			e.target.classList.add("matatabiClick")
			this.$emit("bmc", !this.bmc);
            setTimeout(function(){e.target.classList.remove("matatabiClick")},500);
        }
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.player{   
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin:20px 0;
    padding:20px;
    width: 90vw;
    height:240px;
    border-radius: 8px;
    /* box-shadow:0 0 12px rgba(0,0,0,.2); */
    /* box-shadow: 3px 3px 5px #1b1a1a22, -3px -3px 5px #2b2b2b22; */
    /* border: solid 1px #00000033; */
}
.primary{
    color: var(--primary);
    background: var(--primary);
}
.player__status
{
    display: flex;
    justify-content: start;
    align-items: start;
    gap:8px;
}
.player__status--index
{
    font-size: 25px;
    font-weight: 500;
}
.player__status--league-img
{
    width: 80px;
    aspect-ratio: 1;
}
.player__status-info--name{
    font-size: 25px;
}
.player__status-info--clan{
    font-size: 20px;
}
.player__rank
{
    font-size: 23px;
}

.player__trophy-youtube
{
    display: flex;
    justify-content: start;
    align-items: center;
}

.player__youtube-link{
    cursor:default;
}

.player__youtube-link--icon
{
    width: 40px;
    aspect-ratio: 367/259;
    cursor: pointer;
}


.player__trophy
{
    font-size: 18px;
}

.trophy_container>p{
    font-size: 40px;
}

.player__trophy-icon{
    font-size:40px;
    display:flex;
}
.player__trophy-icon-inner{
    font-size:35px;
    position:relative;
    line-height:50px;
    height:50px;
    display:block;
}
.player__trophy-icon--trophy{
    position:relative;
    height:50px;
    line-height:42px;
    display:block;
}
.matatabi {
    cursor: pointer;
    transition: .2s;
}
.matatabiClick {
    transform: scale(0.9)!important;
    filter: drop-shadow(0 0 2px #000000ff)!important;
}
.matatabi:hover {
    transform: scale(1.01);
    filter: drop-shadow(7px 5px 6px #00000028);
}
@media(max-width:1024px)
{
    .player__status--league-img{
        width:65px;
    }
    .player__status-info--name{
        font-size: 20px;
    }
    .player__status-info--clan
    {
        font-size: 16px;
    }
}
</style>
