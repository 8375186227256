<template>
    <div class="player">
        <div class="player__status">
            <p class="player__status--index">{{ index + 1 }}</p>
            <img v-if="data.matatabi==true" id="matatabi" class="player__status--league-img matatabi" v-show="sort=='current'" :src='"./assets/arena/"+data.currentArena + ".png"' @click="move">
            <img v-else class="player__status--league-img" v-show="sort=='current'" :src="'./assets/arena/' + data.currentArena + '.png'">
            <img class="player__status--league-img" v-show="sort=='best'" :src="'./assets/arena/' + data.bestArena + '.png'">
            <img class="player__status--league-img" v-show="sort=='seasonBest'" :src="'./assets/arena/' + data.seasonArena + '.png'">
            <div>
                <div class="player__status-info">
                    <a class="player__status-info--name" :href="'https://royaleapi.com/player/'+data.inGameId">{{ data.playerName }}</a>
                    <br>
                    <a class="player__status-info--clan" :href="'https://royaleapi.com/clan/'+data.clanId">{{ data.clanName }}</a>
                </div>
            </div>
        </div>
        <span v-if="data.rank!=0" v-show="sort=='now'" class="player__rank">#{{ data.rank }}</span>
        <div class="player__trophy-youtube">
            <a class="player__youtube-link" v-if="data.youTuber=='True'" :href="data.ytLink"><img class="player__youtube-link--icon" :src="'./assets/YTico.png'" alt=""></a>
            <div v-else class="ytico"></div>
            <div class="player__trophy" style="margin-top:10px;margin-left: 20px;">
                <span class="player__trophy-icon" v-show="sort=='current'"><span class="player__trophy-icon-inner"><ion-icon name="trophy-sharp"></ion-icon></span><span class="player__trophy-icon--trophy">{{data.current}}</span></span>
                <span class="player__trophy-icon" v-show="sort=='best'"><span class="player__trophy-icon-inner"><ion-icon name="trophy-sharp"></ion-icon></span><span class="player__trophy-icon--trophy">{{data.best}}</span></span>
                <span class="player__trophy-icon" v-show="sort=='seasonBest'"><span class="player__trophy-icon-inner"><ion-icon name="trophy-sharp"></ion-icon></span><span class="player__trophy-icon--trophy">{{data.seasonBest}}</span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'playerInfo',
	props:["data","index","bmc","sort"],

	methods:{
		move(e) {
			e.target.classList.add("matatabiClick")
			this.$emit("bmc", !this.bmc);
            setTimeout(function(){e.target.classList.remove("matatabiClick")},500);
        }
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.player{   
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin:20px 0;
    padding:20px;
    width: 90vw;
    height:240px;
    border-radius: 8px;
    box-shadow:0 0 12px rgba(0,0,0,.2);
    /* box-shadow: 3px 3px 5px #1b1a1a22, -3px -3px 5px #2b2b2b22; */
    /* border: solid 1px #00000033; */
}
.player__status
{
    display: flex;
    justify-content: start;
    align-items: start;
    gap:8px;
}
.player__status--index
{
    font-size: 25px;
    font-weight: 500;
}
.player__status--league-img
{
    width: 80px;
    aspect-ratio: 1;
}
.player__status-info--name{
    font-size: 25px;
}
.player__status-info--clan{
    font-size: 20px;
}
.player__rank
{
    font-size: 23px;
}

.player__trophy-youtube
{
    display: flex;
    justify-content: start;
    align-items: center;
}

.player__youtube-link{
    cursor:default;
}

.player__youtube-link--icon
{
    width: 40px;
    aspect-ratio: 367/259;
    cursor: pointer;
}


.player__trophy
{
    font-size: 18px;
}

.trophy_container>p{
    font-size: 40px;
}

.player__trophy-icon{
    font-size:40px;
    display:flex;
}
.player__trophy-icon-inner{
    font-size:35px;
    position:relative;
    line-height:50px;
    height:50px;
    display:block;
}
.player__trophy-icon--trophy{
    position:relative;
    height:50px;
    line-height:42px;
    display:block;
}
.matatabi {
    cursor: pointer;
    transition: .2s;
}
.matatabiClick {
    transform: scale(0.9)!important;
    filter: drop-shadow(0 0 2px #000000ff)!important;
}
.matatabi:hover {
    transform: scale(1.01);
    filter: drop-shadow(7px 5px 6px #00000028);
}
@media(max-width:1024px)
{
    .player__status--league-img{
        width:65px;
    }
    .player__status-info--name{
        font-size: 20px;
    }
    .player__status-info--clan
    {
        font-size: 16px;
    }
}
</style>
