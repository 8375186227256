<template>
    <div class="selector">
        <template v-for="(value, index) in values" :key="value">
            <div class="selecotr-item">
                <input type="radio" :id="value" name="selector" :value="value" class="selector-item_radio" @change="keyChange" v-model="sortKey">
                <label :for="value" class="selector-item_label">{{ labels[index] }}</label>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    "name":"selectorTemp",
    props:{
        values:{
            "type":Array,
            "default":()=>["radio1","radio2","radio3"]
        },
        labels_prop:{
            "type":[Array,Boolean],
            "default":false
        }
    },
    data(){
        return{
            sortKey:this.values[0],
            labels:[]


        }
    
    },
    methods:{
        keyChange(){
            this.$emit("keyChange", this.sortKey)
        }
    },
    created(){
        if(!this.labels_prop){
            this.labels=this.values
        }
        else{
            this.labels=this.labels_prop
        }
    }
}
</script>
<style scoped>
:root{
    --white:#fff;
    --smoke-white:#f1f3f5;
    --blue:#4169e1;
}
.container{
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}
.selector{
    position:relative;
    width:60%;
    background-color:var(--smoke-white);
    height:60px;
    display:flex;
    justify-content:space-around;
    align-items:center;
    border-radius:9999px;
    box-shadow:0 0 16px rgba(0,0,0,.2);
    margin-top: 20px;
}
.selecotr-item{
    position:relative;
    flex-basis:calc(70% / 3);
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}
.selector-item_radio{
    appearance:none;
    display:none;
}
.selector-item_label{
    position:relative;
    height:80%;
    width:100%;
    color:#444444aa;
    text-align:center;
    border-radius:9999px;
    line-height:300%;

    transition-duration:.5s;
    transition-property:transform, color, box-shadow;
    transform:none;
}
.selector-item_radio:checked + .selector-item_label{
    background-color:#4169e1;
    color:#fff;
    font-weight:900;
    box-shadow:0 0 4px rgba(0,0,0,.5),0 2px 4px rgba(0,0,0,.5);
    transform:translateY(-2px);
}
@media(max-width:1024px)
{
    .selector{
        width: 90%;
    }
}
</style>
